import * as JQuery from 'jquery';
window.$ = window.jQuery = JQuery.default;
$(document).ready(function (){

    $(".js-accept-cookies").on("click",function (){

        $(".cookies_popup,.extended_cookies_popup").detach()
        $("html").css('overflow','auto')
        $.ajax({
            url: ajaxurl,
            type: "POST",
            dataType: "json",
            data: {action: "acceptCookies"},
            cache: false,
        });
    })
    $(".extended_cookies_popup .close").on("click",function(){
        $(".extended_cookies_popup").fadeOut()
        $("html").css('overflow','auto')
    })
    $(".js-open-settings").on("click",function(){
        $(".extended_cookies_popup").fadeIn();
        $("html").css('overflow','hidden')
    })
    $(".js-custom-checkbox").on("click",function (){
        let ths = $(this)
        $(this).toggleClass("active")
        let checkBox = $(this).find('input')
        checkBox.prop("checked", !checkBox.prop("checked"));
    })
})
